.feedback-title {
  font-size: 2rem;
  font-weight: 600;
  color: black;
  text-align: center; /* Center the title for a cleaner look */
  margin-bottom: 40px;
}

.feedback-box {
  background-color: white; /* Ensures a white background for the boxes */
  border-radius: 10px; /* Round the corners for a cleaner, modern feel */
  padding: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Subtle shadow for separation */
  transition: all 0.3s ease; /* Smooth transition for hover effects */
}

.feedback-box:hover {
  transform: translateY(-10px); /* Slight lift on hover */
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15); /* More prominent shadow on hover */
}

.feedback-box .img {
  width: 80px;
  height: 80px;
  margin: 0 auto;
  overflow: hidden;
  border-radius: 50%; /* Make the image round */
  transition: all 0.3s ease; /* Smooth transition for hover effect */
}

.feedback-box .img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.feedback-box:hover .img img {
  transform: scale(1.1); /* Slight zoom on hover */
}

.feedback-box h4 {
  font-size: 1.25rem;
  margin-top: 15px;
  font-weight: 500;
  color: black;
}

.feedback-text {
  font-size: 0.95rem;
  color: gray;
  margin-bottom: 15px;
}

.stars {
  color: gold;
}

@media (max-width: 768px) {
  .feedback-box {
    margin-bottom: 20px; /* Ensure there’s space between feedback items on small screens */
  }
}
