
.bg-hero {
    background: linear-gradient(110.99deg, #9DBAF1 0%, rgba(67, 95, 149, 0.99) 51.81%, rgba(2, 17, 47, 0.99) 100%);
    animation: bg 12s ease infinite;
    height: 90vh;
    margin-top: -1rem;
}

@keyframes bg{
    0%{
        background-position:  0% 50%;
    }
    50%{
        background-position:  100% 100%;
    }
    100%{
        background-position:  0% 50%;
    }
}

.scrollable-left {
    scrollbar-width: none; /* Firefox */
  }
  .scrollable-left::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera */
  }