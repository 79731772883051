.footer-section {
  background-color: #0A183D;
  color: #FFFFFF;
  padding: 20px 0;
}

.footer-container {
  padding-top: 20px;
}

.footer-logo {
  max-width: 80px;
}

.footer-links ul {
  padding: 0;
  list-style-type: none;
  margin: 0;
}

.footer-links li {
  margin-bottom: 8px;
  font-size: 16px;
}

.social-icon {
  width: 30px;
  height: 30px;
  margin: 0 8px;
}

.social-link {
  color: #FFFFFF;
  text-decoration: none;
}

.social-link:hover .social-icon {
  opacity: 0.8;
}

.footer-copyright {
  font-size: 14px;
  color: #CCCCCC;
  margin-top: 10px;
}
