.leader-section {
  background-color: #f8f9fa;
}

.position {
  text-align: center;
  background-color: white; 
  border-radius: 10px; 
  padding: 20px;
  transition: all 0.3s ease; 
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); 
  margin-bottom: 20px;
  margin-block: 100px;
}

.position:hover {
  transform: translateY(-10px); 
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

.position h4 {
  font-size: 1.5rem;
  color: #6c757d;
}

.position.first h4 {
  color: #f0ad4e;
  font-size: 2rem;
}

.leader-img img {
  width: 150px;
  height: 150px;
  border-radius: 50%; 
  transition: all 0.3s ease; 
}

.position:hover .leader-img img {
  transform: scale(1.1);
}

.first-place-img {
  width: 200px;
  height: 200px;
}

@media (max-width: 768px) { 
  .position {
    display: flex;
    flex-direction: column;
    order: 3;
    margin-bottom: 20px;
  }

  .first {
    order: 1; 
  }

  .position:nth-child(2) {
    order: 2;
  }

  .position:nth-child(3) {
    order: 3;
  }
}
