.contactus-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
}

.map-section {
  flex: 1;
  padding: 10px;
}

.map-section img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.contactus-form {
  flex: 1;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form {
  width: 100%;
  max-width: 400px;
  background-color: rgb(6, 39, 110);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.form-title {
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
}

.form-label {
  font-size: 0.9rem;
  font-weight: 600;
  color: #333;
}

.form-control {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  background-color: #fff;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}

.form-control:focus {
  outline: none;
  border-color: #042e5c;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.25);
}

.submit-btn {
  width: 100%;
  padding: 10px;
  background-color: #06315e;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-btn:hover {
  background-color: #08396d;
}

@media (max-width: 768px) {
  .contactus-wrapper {
    flex-direction: column;
  }

  .map-section {
    margin-bottom: 20px;
  }
}
