
.videos{
 height: 90vh;
 width: 80vw;
 object-fit:fill;
 opacity: 0.7;
}
.hero-section{
    background-color: black;
}
.about_section{
    margin-top: 3rem !important;
}